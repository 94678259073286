import { parse, stringify } from "superjson"
import type { StoreApi } from "zustand"
import { createJSONStorage } from "zustand/middleware"

export function withDevLog<T extends Record<string, unknown>>(store: StoreApi<T>, name: string) {
  console.log(`store:init "${name}"`, store.getState())
  store.subscribe((state, prevState) => {
    const diff = Object.keys(state).reduce((acc, key) => {
      if (state[key] !== prevState[key]) {
        // @ts-expect-error any type
        acc[key] = state[key]
      }
      return acc
    }, {})
    console.log(`store:update "${name}"`, diff)
  })
}

export function superjsonStorage() {
  return createJSONStorage(() => localStorage, {
    reviver: (_key, value) => {
      return parse(value as unknown as string)
    },
    replacer: (_key, value) => {
      return stringify(value)
    },
  })
}
