import { useDynamicContext } from "@dynamic-labs/sdk-react-core"
import * as Sentry from "@sentry/react"
import { useToast } from "@web/hooks/useToast"
import { LoginContext } from "@web/providers/LoginProvider"
import { useFanStore } from "@web/stores/fan"
import { usePostHog } from "posthog-js/react"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"

export function useLoginContext() {
  const posthog = usePostHog()
  const reset = useFanStore((s) => s.reset)
  const { isAuthenticated, setShowAuthFlow, handleLogOut } = useDynamicContext()
  const { showError } = useToast()
  const navigate = useNavigate()

  const context = useContext(LoginContext)
  if (context === null) {
    throw new Error("useLoginContext() was used outside of LoginProvider")
  }

  function login() {
    // TODO: we should do a better job of auto-pulling the fan information
    if (isAuthenticated) {
      logout()
      showError("Error: Please try logging in again")
    } else {
      setShowAuthFlow(true)
    }
  }

  function logout() {
    reset()
    handleLogOut()

    posthog.reset()
    Sentry.setUser(null)

    navigate("/")
  }

  return {
    login,
    logout,
    isLoggedIn: context.isLoggedIn,
  }
}
