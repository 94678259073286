import type { ClaimResponse } from "@api/types"
import type { QueryClient } from "@tanstack/react-query"
import { getQueryKey } from "@trpc/react-query"
import { trpc, trpcLoaderClient } from "@web/lib/trpc"

export async function prefetchClaims(queryClient: QueryClient) {
  const queryKey = getQueryKey(trpc.claim.get, undefined, "query")
  return await queryClient.ensureQueryData({
    queryKey,
    queryFn: () => trpcLoaderClient.claim.get.query(),
  })
}

export function useClaims(initialData?: ClaimResponse[]) {
  return trpc.claim.get.useQuery(undefined, {
    enabled: false, // Do not refetch claims on mount
    staleTime: Number.POSITIVE_INFINITY, // Do not refetch claims repeatedly
    initialData,
  })
}
