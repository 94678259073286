import { normTokenAmount } from "@api/lib/utils"
import { useTokens } from "@web/hooks/queries/useTokens"
import { useFanStore } from "@web/stores/fan"
import { erc20Abi } from "viem"
import { useReadContract } from "wagmi"

export function useTokenBalance(id: string) {
  const fan = useFanStore((s) => s.fan)
  const {
    data: [token] = [],
  } = useTokens(id)

  const { data: rawBalance, isLoading } = useReadContract({
    abi: erc20Abi,
    address: token?.address,
    functionName: "balanceOf",
    args: [fan?.address as `0x${string}`],
    query: {
      enabled: !!token?.address && !!fan?.address,
      refetchInterval: 5_000, // Default polling interval
    },
  })

  const balance = normTokenAmount(rawBalance ?? 0n, token?.decimals ?? 18)

  return { balance, isLoading }
}
