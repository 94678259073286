import type { Fan } from "@api/types"
import { useDynamicContext } from "@dynamic-labs/sdk-react-core"
import * as Sentry from "@sentry/react"
import { useFanMutations } from "@web/hooks/mutations/useFanMutations"
import { useSocialMutations } from "@web/hooks/mutations/useSocialMutations"
import { useFanStore } from "@web/stores/fan"
import { usePostHog } from "posthog-js/react"
import { createContext, useCallback, useEffect } from "react"

interface LoginContext {
  isLoggedIn: boolean
}

export const LoginContext = createContext<LoginContext | null>(null)

interface LoginProviderProps {
  children: React.ReactNode
}

export function LoginProvider(props: LoginProviderProps) {
  const { fan, primarySocial } = useFanStore((s) => ({
    fan: s.fan,
    primarySocial: s.primarySocial,
  }))

  const { fetchExistingFan } = useFanMutations()
  const { fetchExistingSocials } = useSocialMutations()
  const { isAuthenticated, primaryWallet } = useDynamicContext()
  const posthog = usePostHog()

  const isLoggedIn = (isAuthenticated && !!fan && !!primarySocial) ?? false

  const checkForExistingUser = useCallback(async () => {
    const existingFan = (await fetchExistingFan()) as Fan
    if (existingFan) {
      fetchExistingSocials(existingFan)
      posthog.capture("login:session_restore", { ...existingFan })
    }
  }, [fetchExistingFan, fetchExistingSocials, posthog])

  // Identify user for error tracking
  useEffect(() => {
    if (!isLoggedIn || !fan?.id || !fan?.address) return
    posthog.identify(fan.address, { ...fan })
    Sentry.setUser({ id: fan.id, email: fan.email, address: fan.address })
  }, [isLoggedIn, fan?.id, fan?.address])

  // Fallback to restore existing fan sessions
  useEffect(() => {
    if (isLoggedIn || !primaryWallet?.address) return
    checkForExistingUser()
  }, [primaryWallet?.address])

  const context = { isLoggedIn }
  return <LoginContext.Provider value={context}>{props.children}</LoginContext.Provider>
}
