import type { CollectiveResponse } from "@api/types"
import { type QueryClient, useQueryClient } from "@tanstack/react-query"
import { getQueryKey } from "@trpc/react-query"
import { trpc, trpcLoaderClient } from "@web/lib/trpc"
import { useFanStore } from "@web/stores/fan"

function findCachedCollective(queryClient: QueryClient, contract?: string, slug?: string) {
  const address = useFanStore.getState().fan?.address ?? undefined
  const queryKey = getQueryKey(trpc.collective.get, { contract, address }, "query")
  const collective = (queryClient.getQueryData(queryKey) as CollectiveResponse[])?.find((c) => c.slug === slug)

  return collective ? [collective] : undefined
}

export async function prefetchCollectives(queryClient: QueryClient, contract?: string, slug?: string) {
  const address = useFanStore.getState().fan?.address ?? undefined
  const queryKey = getQueryKey(trpc.collective.get, { contract, slug, address }, "query")
  return await queryClient.ensureQueryData({
    queryKey,
    queryFn: () => trpcLoaderClient.collective.get.query({ contract, slug, address }),
  })
}

export function useCollectives(contract?: string, slug?: string, initialData?: CollectiveResponse[]) {
  const address = useFanStore.getState().fan?.address ?? undefined
  const queryClient = useQueryClient()
  return trpc.collective.get.useQuery(
    { contract, slug, address },
    {
      // staleTime: Infinity, // Do not refetch collective info repeatedly
      initialData: () => {
        if (!!contract && initialData?.[0]?.contract === contract) return initialData
        return findCachedCollective(queryClient, contract, slug)
      },
    },
  )
}

export function useCollectivesSuspense(contract?: string, slug?: string, initialData?: CollectiveResponse[]) {
  const address = useFanStore.getState().fan?.address ?? undefined
  const queryClient = useQueryClient()
  return trpc.collective.get.useSuspenseQuery(
    { contract, slug, address },
    {
      staleTime: Number.POSITIVE_INFINITY, // Do not refetch collective info repeatedly
      initialData: () => {
        if (!!contract && initialData?.[0]?.contract === contract) return initialData
        return findCachedCollective(queryClient, contract, slug)
      },
    },
  )
}
