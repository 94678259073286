import type { CollectiveResponse, HoldingResponse, PriceResponse, SparklineResponse } from "@api/types"

export enum Modal {
  Claim = "claim",
  Deposit = "deposit",
  Enter = "enter",
  Fan = "fan",
  FAQs = "faqs",
  Fund = "fund",
  Nav = "nav",
  None = "none",
  Offboard = "offboard",
  Olympics = "olympics",
  Onboard = "onboard",
  Pack = "pack",
  Profile = "profile",
  Redeem = "redeem",
  Referral = "referral",
  Share = "share",
  Social = "social",
  Trade = "trade",
  Withdraw = "withdraw",
}

export interface PricedCollective extends CollectiveResponse, PriceResponse {}
export interface PricedHolding extends HoldingResponse, PriceResponse {}

export interface PricedCollectiveWithSparkline extends PricedCollective {
  sparkline: SparklineResponse["prices"]
}

export type Filter = "activity" | "top" | "holdings" | "owners" | "trending"
