import "@web/styles/index.css"

import { DynamicUserProfile } from "@dynamic-labs/sdk-react-core"
import { DynamicWagmiConnector } from "@dynamic-labs/wagmi-connector"
import { QueryClient, QueryClientProvider, keepPreviousData } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { Layout } from "@web/components/layout/Layout"
import { initSentry, sentryCreateBrowserRouter } from "@web/lib/sentry"
import { trpc, trpcReactClient } from "@web/lib/trpc"
import { collectiveLoader, homeLoader } from "@web/pages/loaders"
import { AnalyticsProvider } from "@web/providers/AnalyticsProvider"
import { DynamicProvider } from "@web/providers/DynamicProvider"
import { LoginProvider } from "@web/providers/LoginProvider"
import { ViemProvider } from "@web/providers/ViemProvider"
import { StrictMode, lazy } from "react"
import { createRoot } from "react-dom/client"
import { RouterProvider } from "react-router-dom"

initSentry(import.meta.env.VITE_SENTRY_DSN, import.meta.env.VITE_SENTRY_HOST)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: import.meta.env.PROD, // Disable retries in development
      placeholderData: keepPreviousData, // Prevent flash of empty content
    },
  },
})

// TODO: how can we further split the bundle?
const HomePage = lazy(() => import("./pages/HomePage"))
const CollectivePage = lazy(() => import("./pages/CollectivePage"))
const ErrorPage = lazy(() => import("./pages/ErrorPage"))

const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <HomePage />,
        loader: homeLoader(queryClient),
      },
      {
        path: "/:contract",
        element: <HomePage />,
        loader: homeLoader(queryClient),
      },
      {
        path: "/:contract/:slug",
        element: <CollectivePage />,
        loader: collectiveLoader(queryClient),
      },
      {
        path: "/seedclub",
        element: <HomePage />,
        loader: homeLoader(queryClient, "/euro24/?modal=referral&ref=seedclubhigher"),
      },
    ],
  },
])

// biome-ignore lint/style/noNonNullAssertion: root element should never be null
createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <AnalyticsProvider
      posthogHost={import.meta.env.VITE_POSTHOG_HOST}
      posthogKey={import.meta.env.VITE_POSTHOG_API_KEY}
    >
      <trpc.Provider client={trpcReactClient} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}>
          <DynamicProvider environmentId={import.meta.env.VITE_DYNAMIC_ENVIRONMENT_ID}>
            <ViemProvider primaryChainId={import.meta.env.VITE_PRIMARY_CHAIN}>
              <DynamicWagmiConnector>
                <LoginProvider>
                  <RouterProvider router={router} />
                </LoginProvider>
                <DynamicUserProfile />
              </DynamicWagmiConnector>
            </ViemProvider>
          </DynamicProvider>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </trpc.Provider>
    </AnalyticsProvider>
  </StrictMode>,
)
