import { base, baseSepolia } from "wagmi/chains"

// Chains
type Chain = typeof base | typeof baseSepolia
export const chains: Record<string, Chain> = {
  base,
  baseSepolia,
}
export const PRIMARY_CHAIN = chains[import.meta.env.VITE_PRIMARY_CHAIN as keyof typeof chains]

export const SLIPPAGE = 5n

// Explorers
const blockExplorers: Record<string, string> = {
  base: "https://basescan.org",
  baseSepolia: "https://sepolia.basescan.org",
}

export const BLOCK_EXPLORER_URL = blockExplorers[import.meta.env.VITE_PRIMARY_CHAIN as string]
export const WALLET_EXPLORER_URL = "https://rainbow.me"

// Platforms
export enum Platform {
  Base = 0,
  ENS = 1,
  Farcaster = 2,
}

export const BASE_COLORS = ["#6194FE", "#9FBEFE", "#0B59FF", "#EFF4FE", "#2168FE"]
export const ENS_COLORS = ["#76D0FA", "#CFC0FC", "#81AAFD", "#A8FAF8", "#A789FF"]
export const FARCASTER_COLORS = ["#8465CB", "#E0D9F9", "#6E4DB8", "#F5F3FC", "#8465CB"]

export const PLATFORM_COLORS = {
  [Platform.Base]: BASE_COLORS,
  [Platform.ENS]: ENS_COLORS,
  [Platform.Farcaster]: FARCASTER_COLORS,
}

export const CHECKMARK_COLOR = [
  "#6194FE", // Default
  "#76D0FA", // ENS
  "#8465CB", // Farcaster
]

// Links
export const CONTACT_EMAIL = "mailto:support@web.game"
export const DISCORD_URL = "https://discord.gg/FgWpX826Bb"
export const DOCS_URL = "https://nilli.gitbook.io/docs"
export const FARCASTER_URL = "https://warpcast.com/~/channel/bracket"
export const TWITTER_URL = "https://twitter.com/bracketHQ"
export const PRIVACY_URL = "https://nilli.com/privacy"
export const TERMS_URL = "https://nilli.com/tos"
