import type { ActivityResponse } from "@api/types"
import { type QueryClient, skipToken } from "@tanstack/react-query"
import { getQueryKey } from "@trpc/react-query"
import { trpc, trpcLoaderClient } from "@web/lib/trpc"

export async function prefetchActivity(
  queryClient: QueryClient,
  type: "fan" | "collective" | "contract",
  contract?: string,
  slug?: string,
  address?: string,
) {
  const queryKey = getQueryKey(trpc.trade.getActivity, { type, contract, slug, address }, "query")
  return await queryClient.ensureQueryData({
    queryKey,
    queryFn: () => trpcLoaderClient.trade.getActivity.query({ type, contract, slug, address }),
  })
}

export function useActivity(
  type: "fan" | "collective" | "contract",
  contract?: string,
  slug?: string,
  address?: string,
  initialData?: ActivityResponse[],
) {
  return trpc.trade.getActivity.useQuery(
    !!contract || !!slug || !!address ? { type, contract, slug, address } : skipToken,
    {
      refetchInterval: 5_000, // Default polling interval
      initialData,
    },
  )
}

export function useActivitySuspense(
  type: "fan" | "collective" | "contract",
  contract?: string,
  slug?: string,
  address?: string,
  initialData?: ActivityResponse[],
) {
  return trpc.trade.getActivity.useSuspenseQuery(
    { type, contract, slug, address },
    {
      refetchInterval: 5_000, // Default polling interval
      initialData,
    },
  )
}
