import type {
  ActivityResponse,
  CollectiveResponse,
  ContractConfigResponse,
  ContractResponse,
  HoldingResponse,
  OwnerResponse,
  PriceResponse,
  TokenResponse,
} from "@api/types"
import type { QueryClient } from "@tanstack/react-query"
import { prefetchActivity } from "@web/hooks/queries/useActivity"
import { prefetchCollectives } from "@web/hooks/queries/useCollectives"
import { prefetchContractConfigs } from "@web/hooks/queries/useContractConfigs"
import { prefetchContracts } from "@web/hooks/queries/useContracts"
import { prefetchHoldings } from "@web/hooks/queries/useHoldings"
import { prefetchOwners } from "@web/hooks/queries/useOwners"
import { prefetchPrices } from "@web/hooks/queries/usePrices"
import { prefetchTokens } from "@web/hooks/queries/useTokens"
import { useAppStore } from "@web/stores"
import { useFanStore } from "@web/stores/fan"
import { type LoaderFunctionArgs, defer } from "react-router-dom"

function parseSearchParams(url: URL, keys: string[]) {
  return keys.reduce(
    (acc, key) => {
      const value = url.searchParams.get(key) ?? undefined
      acc[key] = value
      return acc
    },
    {} as Record<string, string | undefined>,
  )
}

interface HomeLoaderArgs extends LoaderFunctionArgs {
  params: { contract: string }
}

export interface HomeLoaderData {
  initialContracts: ContractResponse[]
  initialContractConfigs: Promise<ContractConfigResponse[]>
  initialCollectives: Promise<CollectiveResponse[]>
  initialPrices: Promise<PriceResponse[]>
  initialTokens: Promise<TokenResponse[]>
  initialHoldings?: Promise<HoldingResponse[]>
  initialActivity?: Promise<ActivityResponse[]>
}

export function homeLoader(queryClient: QueryClient, overridePath?: string) {
  return async ({ request, params }: HomeLoaderArgs): Promise<ReturnType<typeof defer>> => {
    if (overridePath) {
      window.location.href = `${window.location.origin}${overridePath}`
    }

    const url = new URL(request.url)
    const { filter } = parseSearchParams(url, ["filter"])

    const fan = useFanStore.getState().fan
    const contract = params.contract ?? useAppStore.getState().lastContract ?? "parisgymnastics"

    // Always required
    const initialContracts = prefetchContracts(queryClient)
    const initialContractConfigs = prefetchContractConfigs(queryClient, contract)
    const initialCollectives = prefetchCollectives(queryClient, contract)
    const initialPrices = prefetchPrices(queryClient, contract)
    const initialTokens = prefetchTokens(queryClient, contract)

    // Conditional prefetches
    const initialHoldings = fan?.address ? prefetchHoldings(queryClient, { address: fan.address, contract }) : undefined
    const initialActivity = filter === "activity" ? prefetchActivity(queryClient, "contract", contract) : undefined

    // TODO: figure out suspense issues with using defer, have to set contract as a param in the meantime
    // See: https://reactrouter.com/en/main/guides/deferred#when-does-the-suspense-fallback-render
    return defer({
      initialContracts: await initialContracts,
      initialContractConfigs,
      initialCollectives,
      initialPrices,
      initialTokens,
      initialHoldings,
      initialActivity,
    })
  }
}

interface CollectiveLoaderArgs extends LoaderFunctionArgs {
  params: { contract: string; slug: string }
}

export interface CollectiveLoaderData {
  initialContracts: ContractResponse[]
  initialCollectives: CollectiveResponse[]
  initialPrices: PriceResponse[]
  initialTokens: TokenResponse[]
  initialHoldings?: HoldingResponse[]
  initialOwners?: Promise<OwnerResponse[]>
  initialActivity?: Promise<ActivityResponse[]>
}

export function collectiveLoader(queryClient: QueryClient) {
  return async ({ request, params }: CollectiveLoaderArgs): Promise<ReturnType<typeof defer>> => {
    const url = new URL(request.url)
    const { contract, slug } = params
    const { filter } = parseSearchParams(url, ["filter"])

    const fan = useFanStore.getState().fan

    // Always required
    const initialContracts = prefetchContracts(queryClient, contract)
    const initialCollectives = prefetchCollectives(queryClient, contract, slug)
    const initialPrices = prefetchPrices(queryClient, contract, slug)
    const initialTokens = prefetchTokens(queryClient, contract)

    // Conditional prefetches
    const initialHoldings = fan?.address
      ? prefetchHoldings(queryClient, { address: fan.address, contract, slug })
      : undefined
    const initialOwners = filter !== "activity" ? prefetchOwners(queryClient, contract, slug) : undefined
    const initialActivity =
      filter === "activity" ? prefetchActivity(queryClient, "collective", contract, slug) : undefined

    // TODO: do these need to be awaited?
    return defer({
      initialContracts: await initialContracts,
      initialCollectives: await initialCollectives,
      initialPrices: await initialPrices,
      initialTokens: await initialTokens,
      initialHoldings: await initialHoldings,
      initialOwners,
      initialActivity,
    })
  }
}
