import { transformer } from "@api/lib/trpc"
import type { AppRouter } from "@api/routers"
import { getAuthToken } from "@dynamic-labs/sdk-react-core"
import { createTRPCClient, httpBatchLink, httpLink } from "@trpc/client"
import { createTRPCReact } from "@trpc/react-query"

export const trpc = createTRPCReact<AppRouter>()
export const trpcReactClient = trpc.createClient({
  links: [
    httpBatchLink({
      url: `${import.meta.env.VITE_BACKEND_ENDPOINT}/trpc`,
      transformer,
      headers() {
        const token = getAuthToken()
        return {
          ...(token && { Authorization: `Bearer ${token}` }),
        }
      },
    }),
  ],
})

export const trpcClient = createTRPCClient<AppRouter>({
  links: [
    httpBatchLink({
      url: `${import.meta.env.VITE_BACKEND_ENDPOINT}/trpc`,
      transformer,
      headers() {
        const token = getAuthToken()
        return {
          ...(token && { Authorization: `Bearer ${token}` }),
        }
      },
    }),
  ],
})

export const trpcLoaderClient = createTRPCClient<AppRouter>({
  links: [
    httpLink({
      url: `${import.meta.env.VITE_BACKEND_ENDPOINT}/trpc`,
      transformer,
      headers() {
        const token = getAuthToken()
        return {
          ...(token && { Authorization: `Bearer ${token}` }),
        }
      },
    }),
  ],
})
