import type { StatsResponse } from "@api/types"
import { type QueryClient, skipToken } from "@tanstack/react-query"
import { getQueryKey } from "@trpc/react-query"
import { trpc, trpcLoaderClient } from "@web/lib/trpc"

export async function prefetchStats(queryClient: QueryClient, contract?: string) {
  const queryKey = getQueryKey(trpc.trade.getStats, { contract }, "query")
  return queryClient.ensureQueryData({
    queryKey,
    queryFn: () => trpcLoaderClient.trade.getStats.query({ contract }),
  })
}

export function useStats(contract?: string, initialData?: StatsResponse) {
  return trpc.trade.getStats.useQuery(contract ? { contract } : skipToken, {
    gcTime: 0,
    initialData,
  })
}

export function useStatsSuspense(contract: string, initialData?: StatsResponse) {
  return trpc.trade.getStats.useSuspenseQuery(
    { contract },
    {
      gcTime: 0,
      initialData: () => (initialData?.id === contract ? initialData : undefined),
    },
  )
}
