import { formatUnits } from "viem"

function bigintToNumber(value: bigint | number | string, tokenDecimals: number) {
  const bn = typeof value === "bigint" ? value : BigInt(value)
  return Number.parseFloat(formatUnits(bn, tokenDecimals))
}

const isInvalidNumber = (value: string | number | bigint) =>
  (typeof value === "number" && Number.isNaN(value)) || !value || value === "NaN"

export function formatPrice(value: string | number, decimals = 2): string {
  if (isInvalidNumber(value)) return "0.00"

  return Number(value).toLocaleString("en-US", {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  })
}

export function formatPriceUSD(value: number | string, decimals = 2): string {
  if (isInvalidNumber(value)) return "0.00"

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(Number(value))
}

export function formatRawPrice(value: number | bigint | string, tokenDecimals: number, decimals = 2) {
  if (isInvalidNumber(value)) return "0.00"

  return bigintToNumber(value, tokenDecimals).toLocaleString("en-US", {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  })
}
