import type { TokenResponse } from "@api/types"
import { type QueryClient, useQueryClient } from "@tanstack/react-query"
import { getQueryKey } from "@trpc/react-query"
import { trpc, trpcLoaderClient } from "@web/lib/trpc"

function findCachedToken(queryClient: QueryClient, id?: string) {
  const queryKey = getQueryKey(trpc.token.get, {}, "query")
  const token = (queryClient.getQueryData(queryKey) as TokenResponse[])?.find((t) => t.id === id)
  return token ? [token] : undefined
}

export async function prefetchTokens(queryClient: QueryClient, id?: string) {
  const queryKey = getQueryKey(trpc.token.get, { id }, "query")
  return await queryClient.ensureQueryData({
    queryKey,
    queryFn: () => trpcLoaderClient.token.get.query({ id }),
  })
}

export function useTokens(id?: string, initialData?: TokenResponse[]) {
  const queryClient = useQueryClient()
  return trpc.token.get.useQuery(
    { id },
    {
      initialData: initialData ?? (() => findCachedToken(queryClient, id)),
    },
  )
}

export function useTokensSuspense(id?: string, initialData?: TokenResponse[]) {
  const queryClient = useQueryClient()
  return trpc.token.get.useSuspenseQuery(
    { id },
    {
      initialData: initialData ?? (() => findCachedToken(queryClient, id)),
    },
  )
}
