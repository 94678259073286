import { PRIMARY_CHAIN } from "@web/lib/constants"
import { useEffect } from "react"
import { useSwitchChain } from "wagmi"

export function usePrimaryChainSwitch() {
  const { data: chain, switchChain } = useSwitchChain()

  useEffect(() => {
    if (chain?.id !== PRIMARY_CHAIN.id) {
      switchChain({ chainId: PRIMARY_CHAIN.id })
    }
  }, [chain, switchChain])
}
