import type { PackResponse } from "@api/types"
import { Button } from "@web/components/ui/button"
import { useAnalytics } from "@web/hooks/useAnalytics"
import { useModal } from "@web/hooks/useModal"
import { sharePage } from "@web/lib/utils"
import { Modal } from "@web/types"
// import { Share } from "lucide-react"

interface ShareButtonProps {
  pack: PackResponse
}

const events = {
  share: "share_button:share_button_click",
}

export function ShareButton({ pack }: ShareButtonProps) {
  const analytics = useAnalytics(events)
  const { openModal } = useModal()

  function handleClickShare() {
    analytics.share()

    if (!pack || pack.balance === 0) {
      sharePage()
    } else if (pack.balance > 0) {
      openModal(Modal.Share)
    }
  }

  return (
    <Button
      variant="outline"
      size="lg"
      onClick={handleClickShare}
      className="rounded-full border-2 border-blue-secondary/65 px-[1rem] font-semibold text-[1.0625rem] text-blue-primary tracking-[-.015rem] transition active:border-blue-primary"
    >
      Share
    </Button>
  )
}
