import type { Filter, Modal } from "@web/types"
import { useSearchParams } from "react-router-dom"
import type { Address } from "viem"

interface ModalState {
  address?: Address
  code?: string
  contract?: string
  filter?: Filter
  isBuy?: boolean
  price?: number
  quantity?: number
  slug?: string
  token?: string
}

export function useModal() {
  const [searchParams, setSearchParams] = useSearchParams()
  const activeModal = searchParams.get("modal") ?? undefined

  function openModal(modal: Modal, state?: ModalState) {
    const newParams = Object.fromEntries(searchParams.entries())
    newParams.modal = modal

    if (state) {
      for (const [key, value] of Object.entries(state)) {
        newParams[key] = value
      }
    }
    setSearchParams(newParams)
  }

  function closeModal(open: boolean) {
    if (open) return
    searchParams.delete("modal")
    setSearchParams(searchParams)

    // Remove modal-specific params
    const keysToRemove = ["address", "code", "contract", "isBuy", "price", "quantity", "slug", "token"]
    for (const key of keysToRemove) {
      searchParams.delete(key)
    }
    setTimeout(() => setSearchParams(searchParams), 300) // Wait for modal to close before updating search params
  }

  return { activeModal, openModal, closeModal }
}
