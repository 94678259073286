import * as Sentry from "@sentry/react"
import { reactRouterV6BrowserTracingIntegration } from "@sentry/react"
import { useEffect } from "react"
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom"

export function initSentry(sentryDsn: string, sentryHost: string) {
  if (import.meta.env.MODE !== "production") return // Do not start in development

  Sentry.init({
    dsn: sentryDsn,
    tunnel: sentryHost,
    environment: import.meta.env.NODE_ENV,
    integrations: [
      reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    // TODO: adjsut later to smth like 0.5, once more users use the app
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracePropagationTargets: ["localhost", /^https:\/\/(dev\.)?api\.bracket\.game/],
  })
}

export const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)
