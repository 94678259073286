import { superjsonStorage, withDevLog } from "@web/stores/utils"
import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"
import { immer } from "zustand/middleware/immer"

interface State {
  lastContract: string
  referralId?: string
  hasSeenOnboardModal: boolean
}

interface Actions {
  setLastContract: (lastContract: string) => void
  setReferralId: (referralId: string | undefined) => void
  setHasSeenOnboardModal: (hasSeenOnboardModal: boolean) => void
  reset: () => void
}

const initialState: State = {
  lastContract: "parisgymnastics",
  referralId: undefined,
  hasSeenOnboardModal: false,
}

export const useAppStore = create<State & Actions>()(
  devtools(
    persist(
      immer((set) => ({
        ...initialState,
        setLastContract: (lastContract) => set({ lastContract }),
        setReferralId: (referralId) => set({ referralId }),
        setHasSeenOnboardModal: (hasSeenOnboardModal) => set({ hasSeenOnboardModal }),
        reset: () => set(initialState),
      })),
      {
        name: "bracket.store",
        version: 9, // Version last updated: 2024-06-28
        storage: superjsonStorage(),
        // exclude referralId from persisting

        partialize: ({ referralId, ...rest }) => rest,
      },
    ),
  ),
)

if (import.meta.env.DEV) {
  withDevLog(useAppStore, "appStore")
}
