// @ts-nocheck TODO: tsc performance hotspot
import type { ClaimResponse } from "@api/types"
import { Button } from "@web/components/ui/button"
import { useAnalytics } from "@web/hooks/useAnalytics"
import { useModal } from "@web/hooks/useModal"
import { Modal } from "@web/types"
import { ChevronRight } from "lucide-react"

interface ClaimButtonProps {
  claims: ClaimResponse[]
}

const events = {
  redeem: "claim_button:claim_button_click",
}

export function ClaimButton({ claims }: ClaimButtonProps) {
  const analytics = useAnalytics(events)
  const { openModal } = useModal()

  function handleClickClaim() {
    analytics.redeem()
    openModal(Modal.Claim)
  }

  return (
    <Button
      size="lg"
      variant="default"
      onClick={handleClickClaim}
      className="relative flex w-full grow items-center justify-center bg-blue-primary font-semibold text-[1.0625rem] transition-all"
    >
      <span className="mb-0.5 flex items-center">
        <span className="mr-[.33rem] rounded-md bg-white bg-opacity-20 px-[.43rem] py-[.22rem] font-bold text-white text-xs ">
          {claims.length}
        </span>
        Pack{claims.length > 1 ? "s" : ""} to Open
      </span>
      <ChevronRight className="absolute right-2 pr-[.2rem] text-white" />
    </Button>
  )
}
