import type { Fan, SocialResponse } from "@api/types"
import type { ProviderEnum } from "@dynamic-labs/types"
import { useAnalytics } from "@web/hooks/useAnalytics"
import { useToast } from "@web/hooks/useToast"
import { trpcClient as trpc } from "@web/lib/trpc"

const events = {
  link: "social:profile_link",
  update: "social:profile_update",
  primary: "social:primary_update",
}

// NOTE: Should NOT update fan store to prevent race conditions with Dynamic callbacks
export function useSocialMutations() {
  const analytics = useAnalytics(events)
  const { showError, showWarning } = useToast()

  async function fetchExistingSocials(fan: Fan): Promise<SocialResponse[] | undefined> {
    try {
      const existingSocials = await trpc.social.get.query({ id: fan.id })
      return existingSocials
    } catch (error) {
      showWarning("Failed to fetch existing socials", error)
    }
  }

  async function createNewSocial(network: ProviderEnum) {
    try {
      if (!network) throw new Error("Provider is required")
      analytics.link({ network })

      const newSocial = await trpc.social.create.mutate({ network })
      return newSocial
    } catch (error) {
      showError("Failed to save new social", error)
    }
  }

  async function updatePrimarySocial(social: SocialResponse) {
    try {
      analytics.primary({ social })

      const primarySocial = await trpc.fan.update.mutate({ primarySocialId: social.id })
      return primarySocial
    } catch (error) {
      showWarning("Failed to update primary social", error)
    }
  }

  return { fetchExistingSocials, createNewSocial, updatePrimarySocial }
}
