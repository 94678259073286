import type { HoldingResponse } from "@api/types"
import { type QueryClient, skipToken } from "@tanstack/react-query"
import { getQueryKey } from "@trpc/react-query"
import { trpc, trpcLoaderClient } from "@web/lib/trpc"

export async function prefetchHoldings(
  queryClient: QueryClient,
  {
    contract,
    slug,
    address,
  }: {
    contract?: string
    slug?: string
    address: string
  },
) {
  const queryKey = getQueryKey(trpc.trade.getHoldings, { contract, slug, address }, "query")
  return await queryClient.ensureQueryData({
    queryKey,
    queryFn: () => trpcLoaderClient.trade.getHoldings.query({ contract, slug, address }),
  })
}

export function useHoldings(
  {
    contract,
    slug,
    address,
  }: {
    contract?: string
    slug?: string
    address?: string | null
  },
  initialData?: HoldingResponse[],
) {
  return trpc.trade.getHoldings.useQuery(address ? { contract, slug, address } : skipToken, {
    refetchInterval: 5_000, // Default polling interval
    initialData,
  })
}

export function useHoldingsSuspense(
  {
    contract,
    slug,
    address,
  }: {
    contract?: string
    slug?: string
    address: string
  },
  initialData?: HoldingResponse[],
) {
  return trpc.trade.getHoldings.useSuspenseQuery(
    { contract, slug, address },
    {
      refetchInterval: 5_000, // Default polling interval
      initialData,
    },
  )
}
