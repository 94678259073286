import superjson from "superjson"

export const transformer = {
  input: {
    serialize: (v: unknown) => v,
    // This `eval` only ever happens on the **client**
    deserialize: (v: unknown) => v,
  },
  output: superjson,
}
