import { Button } from "@web/components/ui/button"
import { useAnalytics } from "@web/hooks/useAnalytics"
import { useModal } from "@web/hooks/useModal"
import { Modal } from "@web/types"
import { ChevronRight } from "lucide-react"

const events = {
  purchase: "pack_button:pack_button_click",
}

export function PackButton() {
  const analytics = useAnalytics(events)
  const { openModal } = useModal()

  function handleClickPurchase() {
    analytics.purchase()
    openModal(Modal.Pack)
  }

  return (
    <Button
      size="lg"
      variant="default"
      onClick={handleClickPurchase}
      className="relative flex w-full grow items-center justify-center bg-blue-primary text-[1.0625rem] transition-all"
    >
      <span className="mb-0.5 flex items-center">
        <span className="font-semibold">Enter Bracket</span>&nbsp;<span className="font-medium">($20)</span>
      </span>
      <ChevronRight className="absolute right-2 pr-[.2rem] text-white" />
    </Button>
  )
}
