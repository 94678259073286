import type { ContractResponse } from "@api/types"
import { type QueryClient, useQueryClient } from "@tanstack/react-query"
import { getQueryKey } from "@trpc/react-query"
import { trpc, trpcLoaderClient } from "@web/lib/trpc"

function findCachedContract(queryClient: QueryClient, id?: string) {
  const queryKey = getQueryKey(trpc.contract.get, {}, "query")
  const contract = (queryClient.getQueryData(queryKey) as ContractResponse[])?.find((c) => c.id === id)
  return contract ? [contract] : undefined
}

export async function prefetchContracts(queryClient: QueryClient, id?: string) {
  const queryKey = getQueryKey(trpc.contract.get, { id }, "query")
  return await queryClient.ensureQueryData({
    queryKey,
    queryFn: () => trpcLoaderClient.contract.get.query({ id }),
  })
}

export function useContracts(id?: string, initialData?: ContractResponse[]): { data?: ContractResponse[] } {
  const queryClient = useQueryClient()
  return trpc.contract.get.useQuery(
    { id },
    {
      initialData: () => {
        if (!!id && initialData?.[0]?.id === id) return initialData
        return findCachedContract(queryClient, id)
      },
    },
  )
}

export function useContractsSuspense(id?: string, initialData?: ContractResponse[]) {
  const queryClient = useQueryClient()
  return trpc.contract.get.useSuspenseQuery(
    { id },
    {
      initialData: () => {
        if (!!id && initialData?.[0]?.id === id) return initialData
        return findCachedContract(queryClient, id)
      },
    },
  )
}
