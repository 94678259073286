import type { Properties } from "posthog-js"
import { usePostHog } from "posthog-js/react"
import { useMemo } from "react"

export function useAnalytics<T extends Record<string, string>>(events: T) {
  const posthog = usePostHog()

  return useMemo(
    () =>
      Object.keys(events).reduce(
        (acc, evenType: keyof T) => {
          acc[evenType] = (properties?: Properties | null) => posthog.capture(events[evenType], properties)
          return acc
        },
        {} as Record<keyof T, (properties?: Properties | null) => void>,
      ),
    [posthog, events],
  )
}
