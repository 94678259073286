import type { PackResponse } from "@api/types"
import { type QueryClient, skipToken } from "@tanstack/react-query"
import { getQueryKey } from "@trpc/react-query"
import { trpc, trpcLoaderClient } from "@web/lib/trpc"

function isPackResponse(item: unknown): item is PackResponse {
  return item !== undefined
}

export async function prefetchPack(queryClient: QueryClient, code: string) {
  const queryKey = getQueryKey(trpc.pack.get, { code }, "query")
  return await queryClient.ensureQueryData({
    queryKey,
    queryFn: () => trpcLoaderClient.pack.get.query({ code }),
  })
}

export function usePack(code?: string, initialData?: PackResponse[]) {
  return trpc.pack.get.useQuery(code ? { code } : skipToken, {
    initialData,
  })
}

export function usePacks(codes: string[]) {
  const queries = trpc.useQueries((t) => codes.map((code) => t.pack.get({ code })))
  const packs = queries.flatMap((query) => query.data).filter(isPackResponse)
  return packs
}

export function usePackSuspense(code: string, initialData?: PackResponse[]) {
  return trpc.pack.get.useSuspenseQuery(
    { code },
    {
      initialData,
    },
  )
}

export function usePacksSuspense(codes: string[]) {
  const queries = trpc.useSuspenseQueries((t) => codes.map((code) => t.pack.get({ code })))
  const packs = queries.flat().filter(isPackResponse)
  return packs
}
