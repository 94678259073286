import { AvatarFallback, AvatarImage, Avatar as AvatarRoot } from "@web/components/ui/avatar"
import { PLATFORM_COLORS, Platform } from "@web/lib/constants"
import { default as BoringAvatar } from "boring-avatars"

interface AvatarProps {
  src?: string | null
  alt: string
  acronym?: string
  primaryColor?: string | null
  secondaryColor?: string | null
  className?: string
}

export function Avatar(props: AvatarProps) {
  return (
    <AvatarRoot className={props.className}>
      <AvatarImage src={props.src ?? undefined} alt={props.alt} className="block object-cover object-center" />

      {/* Fallback for image avatars */}
      {props.src && <AvatarFallback className="animate-pulse bg-zinc-200" />}

      {/* Fallback for acronym avatars */}
      {!props.src && (
        <AvatarFallback style={{ backgroundColor: props.primaryColor ?? "#fffff" }}>
          {/* If an acronym is provided, use it */}
          {props.acronym && !props.src && (
            <span className="font-bold uppercase italic" style={{ color: props.secondaryColor ?? "#ffffff" }}>
              {props.acronym}
            </span>
          )}

          {/* If no acronym is provided, use the Boring Avatar */}
          {!props.acronym && !props.src && (
            <BoringAvatar size="100%" colors={PLATFORM_COLORS[Platform.Base]} name={props.alt} />
          )}
        </AvatarFallback>
      )}
    </AvatarRoot>
  )
}
