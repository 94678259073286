import type { ContractConfigResponse } from "@api/types"
import { type QueryClient, useQueryClient } from "@tanstack/react-query"
import { getQueryKey } from "@trpc/react-query"
import { trpc, trpcLoaderClient } from "@web/lib/trpc"

function findCachedContractConfigs(queryClient: QueryClient, id?: string) {
  const queryKey = getQueryKey(trpc.contract.getConfigs, {}, "query")
  const config = (queryClient.getQueryData(queryKey) as ContractConfigResponse[])?.find((c) => c.id === id)
  return config ? [config] : undefined
}

export async function prefetchContractConfigs(queryClient: QueryClient, id?: string) {
  const queryKey = getQueryKey(trpc.contract.getConfigs, { id }, "query")
  return await queryClient.ensureQueryData({
    queryKey,
    queryFn: () => trpcLoaderClient.contract.getConfigs.query({ id }),
  })
}

export function useContractConfigs(id?: string, initialData?: ContractConfigResponse[]) {
  const queryClient = useQueryClient()
  return trpc.contract.getConfigs.useQuery(
    { id },
    {
      initialData: () => {
        if (!!id && initialData?.[0]?.id === id) return initialData
        return findCachedContractConfigs(queryClient, id)
      },
    },
  )
}

export function useContractConfigsSuspense(id?: string, initialData?: ContractConfigResponse[]) {
  const queryClient = useQueryClient()
  return trpc.contract.getConfigs.useSuspenseQuery(
    { id },
    {
      initialData: () => {
        if (!!id && initialData?.[0]?.id === id) return initialData
        return findCachedContractConfigs(queryClient, id)
      },
    },
  )
}
