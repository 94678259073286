import type { Fan, SocialResponse } from "@api/types"
import { withDevLog } from "@web/stores/utils"
import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"
import { immer } from "zustand/middleware/immer"

interface State {
  fan?: Fan
  socials: SocialResponse[]
  primarySocial?: SocialResponse
}

interface Actions {
  setFan: (fan: Fan | undefined) => void
  setSocials: (socials: SocialResponse[]) => void
  setPrimarySocial: (primarySocial: SocialResponse | undefined) => void
  reset: () => void
}

const initialState: State = {
  fan: undefined,
  socials: [],
  primarySocial: undefined,
}

export const useFanStore = create<State & Actions>()(
  devtools(
    persist(
      immer((set) => ({
        ...initialState,
        setFan: (fan) => set({ fan }),
        setSocials: (socials) => set({ socials }),
        setPrimarySocial: (primarySocial) => set({ primarySocial }),
        reset: () => set(initialState),
      })),
      { name: "bracket.fan", version: 2 }, // Version last updated: 2024-04-10
    ),
  ),
)

if (import.meta.env.DEV) {
  withDevLog(useFanStore, "fanStore")
}
