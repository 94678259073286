import Logo from "@web/assets/logo.svg?react"
import { useContractConfigs } from "@web/hooks/queries/useContractConfigs"
import { useContracts } from "@web/hooks/queries/useContracts"
import { useStats } from "@web/hooks/queries/useStats"
import { useTokens } from "@web/hooks/queries/useTokens"
import { useModal } from "@web/hooks/useModal"
import { formatPrice } from "@web/lib/formatters"
import { cn } from "@web/lib/utils"
import { useAppStore } from "@web/stores"
import { Modal } from "@web/types"
import { useEffect, useState } from "react"
import { default as ReactMarquee } from "react-fast-marquee"
import { useParams } from "react-router-dom"

export function Marquee() {
  const lastContract = useAppStore((s) => s.lastContract)
  const { openModal } = useModal()
  const [isLoading, setIsLoading] = useState(true)

  const { contract } = useParams()
  const activeContract = contract ?? lastContract
  const {
    data: [config] = [],
  } = useContractConfigs(activeContract)
  const { data, refetch } = useStats(activeContract)

  const {
    data: [contractData] = [],
  } = useContracts(activeContract)
  const {
    data: [token] = [],
  } = useTokens(contractData?.token)

  // Only refetch stats when the contract changes
  useEffect(() => {
    function updateStats() {
      setIsLoading(true)
      setTimeout(async () => {
        await refetch()
        setIsLoading(false)
      }, 800)
    }

    updateStats()
  }, [activeContract, refetch])

  return (
    <div className="relative flex h-[2.2rem] w-full items-center overflow-hidden bg-[#cdb76e] text-[0.8125rem] text-white transition-all">
      <button type="button" onClick={() => openModal(Modal.FAQs)}>
        <ReactMarquee
          speed={50}
          className={cn("tracking-wide opacity-0 transition-opacity duration-300", { "opacity-100": !isLoading })}
        >
          <span className="mx-2 font-semibold uppercase">{config?.displayName}</span>
          <span className="mx-2 uppercase">
            PRIZE POOL: <strong>{formatPrice(data?.prizePool ?? 0)}</strong> {token?.ticker}
          </span>
          <span className="mx-2">
            <span>
              OWNERS: <strong>{data?.uniqueOwners}</strong>
            </span>
          </span>
          <span className="mx-2 uppercase">
            24H VOL: <strong>{formatPrice(data?.volume24h ?? 0)}</strong> {token?.ticker}
          </span>
        </ReactMarquee>
      </button>

      {/* BLUE BACKGROUND */}
      <button
        type="button"
        onClick={(e) => {
          e.stopPropagation() // Prevents the FAQ modal from opening
          openModal(Modal.Nav)
        }}
        className="-ml-[1.15rem] -skew-x-12 absolute z-10 flex h-full w-[4.5rem] flex-col items-center justify-center rounded-tr-[.15rem] rounded-br-[.15rem] bg-gradient-to-r from-[#948012] to-[#b09a3d] pl-[0.1rem] backdrop-blur-[.1rem] transition"
      >
        <Logo className="ml-[1.0rem] h-[1.25rem] skew-x-12 cursor-pointer text-white transition hover:opacity-100 active:scale-95" />
      </button>

      {/* SUBTLE SHADOW */}
      <div className="-mr-[.1rem] -skew-x-12 absolute left-0 z-5 flex h-full w-[3.8rem] flex-col items-center justify-center rounded-tl-[.15rem] rounded-bl-[.15rem] bg-blue-[#9a8754]/[.05] pl-[0.1rem] blur-sm backdrop-blur-[0rem] transition" />
    </div>
  )
}
