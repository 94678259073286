import { chains } from "@web/lib/constants"
import { trpcClient } from "@web/lib/trpc"
import { createContext, useEffect, useState } from "react"
import { mainnet } from "viem/chains"
import { http, WagmiProvider, createConfig, fallback } from "wagmi"

async function initViem(primaryChainId: string) {
  const [primaryChain, ensChain] = await trpcClient.chain.get.query({ id: primaryChainId })

  const config = createConfig({
    chains: [chains[primaryChainId], mainnet],
    multiInjectedProviderDiscovery: false,
    // @ts-expect-error NOTE: Chain union type in constants throws error
    transports: {
      [primaryChain.chainId]: fallback([http(primaryChain.primaryRpcUrl), http(primaryChain.secondaryRpcUrl)]),
      [mainnet.id]: fallback([http(ensChain.primaryRpcUrl), http(ensChain.secondaryRpcUrl)]),
    },
  })

  return config
}

interface ViemContext {
  config: ReturnType<typeof createConfig>
}

export const ViemContext = createContext<ViemContext | null>(null)

interface ViemProviderProps {
  primaryChainId: string
  children: React.ReactNode
}

export function ViemProvider({ primaryChainId, children }: ViemProviderProps) {
  const [config, setConfig] = useState<ReturnType<typeof createConfig>>()

  // Initialize viem config with chain-specific RPCs
  useEffect(() => {
    async function getConfig() {
      const config = await initViem(primaryChainId)
      setConfig(config)
    }

    if (config) return
    getConfig()
  }, [])

  if (!config) return null

  const context = { config }
  return (
    <ViemContext.Provider value={context}>
      <WagmiProvider config={config}>{children}</WagmiProvider>
    </ViemContext.Provider>
  )
}
