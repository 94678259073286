// import DegenCoins from "@web/assets/degen-coins.svg?react"
import { Button } from "@web/components/ui/button"
import { useAnalytics } from "@web/hooks/useAnalytics"
import { useModal } from "@web/hooks/useModal"
import { Modal, type PricedCollective } from "@web/types"
import { ChevronRight, Trophy } from "lucide-react"

import { useSearchParams } from "react-router-dom"

interface RedeemButtonProps {
  collective: PricedCollective
}

const events = {
  redeem: "redeem_button:redeem_button_click",
}

export function RedeemButton({ collective }: RedeemButtonProps) {
  const analytics = useAnalytics(events)
  const { openModal } = useModal()
  const [searchParams, setSearchParams] = useSearchParams()

  function handleClickRedeem() {
    analytics.redeem()
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      contract: collective.contract,
      slug: collective.slug,
    })
    openModal(Modal.Redeem, { contract: collective.contract, slug: collective.slug })
  }

  return (
    <Button
      size="lg"
      variant="default"
      onClick={handleClickRedeem}
      className="relative flex h-14 w-full items-center justify-center bg-green-secondary font-semibold text-[1.0625rem] transition hover:bg-green-secondary active:bg-green-secondary"
    >
      <Trophy className="mt-1 mr-[.4rem] mb-[.33rem] size-[1.1rem] text-white/70 " />
      Claim your Winnings <ChevronRight className="absolute right-2 pr-[.2rem] text-white" />
    </Button>
  )
}
