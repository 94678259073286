import LogotypeSplit from "@web/assets/logotype-split.svg?react"
import { Marquee } from "@web/components/layout/Marquee"
import { Avatar } from "@web/components/shared/Avatar"
import { Button } from "@web/components/ui/button"
import { useContracts } from "@web/hooks/queries/useContracts"
import { useTokens } from "@web/hooks/queries/useTokens"
import { useAnalytics } from "@web/hooks/useAnalytics"
import { useLoginContext } from "@web/hooks/useLoginContext"
import { useModal } from "@web/hooks/useModal"
import { useTokenBalance } from "@web/hooks/useTokenBalance"
import { formatPrice } from "@web/lib/formatters"
import { cn } from "@web/lib/utils"
import { useAppStore } from "@web/stores"
import { useFanStore } from "@web/stores/fan"
import { Modal } from "@web/types"
import { useNavigate, useParams } from "react-router-dom"

const events = {
  profile: "header:profile_button_click",
  login: "header:login_button_click",
  menu: "header:menu_button_click",
}

export function Header() {
  const analytics = useAnalytics(events)
  const lastContract = useAppStore((s) => s.lastContract)
  const primarySocial = useFanStore((s) => s.primarySocial)
  const { openModal } = useModal()

  const { contract, slug } = useParams()
  const activeContract = contract ?? lastContract
  const {
    data: [contractData] = [],
  } = useContracts(activeContract)
  const {
    data: [token] = [],
  } = useTokens(contractData?.token)

  const navigate = useNavigate()
  const { isLoggedIn, login } = useLoginContext()
  const { balance } = useTokenBalance(contractData?.token)

  function handleClickLogo() {
    if (slug) navigate("/")
    else openModal(Modal.Nav)
  }

  function handleClickProfile() {
    analytics.profile()
    openModal(Modal.Profile)
  }

  function handleClickLogin() {
    analytics.login()
    login()
  }

  return (
    <header className="flex w-full flex-col items-center">
      <Marquee />
      {/* TODO: Activate only when we have notifications and figure out flow to direct downloads. Need to figure out how to implement it into existing UI, likely pushing down the marquee or logo section only */}
      {/* <AppBanner /> */}

      <div className="mt-[.75rem] flex w-full items-center justify-between px-4">
        <button type="button" onClick={handleClickLogo}>
          <LogotypeSplit className="-ml-[.15rem] w-[11.3rem] transition-transform duration-75 active:scale-[98.5%]" />
        </button>

        {/* Logged in state */}
        {isLoggedIn && token && (
          <>
            <Button
              variant="outline"
              onClick={handleClickProfile}
              className="-mt-[.925rem] ml-auto flex h-[2.25rem] rounded-full border-gray-light px-1.5"
            >
              {/* TOKEN */}
              <img
                src={token.imageSrc ?? ""}
                alt={`Symbol for ${token.ticker}`}
                className="ml-[.05rem] size-[1.5rem] rounded-full"
              />
              <span
                className={cn("mr-[.4rem] ml-[.4rem] font-medium text-[.875rem]", {
                  "text-blue-primary/85": balance === 0,
                  "text-blue-primary": balance > 0,
                })}
              >
                {formatPrice(balance)}
              </span>
            </Button>

            <Button
              onClick={handleClickProfile}
              className="-mt-[.925rem] ml-2 h-fit rounded-full p-0 hover:scale-[102%] active:scale-[97%]"
            >
              <div className="relative">
                <div className="absolute z-0 h-8 w-8 rounded-full bg-white" />{" "}
                {/* White circle background to cover blue background issue */}
                <Avatar
                  src={primarySocial?.imageSrc}
                  alt={`Avatar of ${primarySocial?.username}`}
                  className="relative z-10 size-8"
                />
              </div>
            </Button>
          </>
        )}

        {/* Logged out state */}
        {!isLoggedIn && (
          <>
            <Button
              onClick={handleClickLogin}
              className="-mt-[.925rem] mr-[.4rem] ml-auto bg-white px-[.9rem] pt-[.44rem] font-semibold text-[.9375rem] text-blue-primary hover:bg-blue-light active:bg-blue-light active:bg-none"
            >
              Log in
            </Button>
            <Button
              onClick={handleClickLogin}
              className="-mt-[.925rem] bg-blue-primary px-[.9rem] pt-[.44rem] font-semibold text-[.9375rem] hover:brightness-105"
            >
              Sign Up
            </Button>
          </>
        )}
      </div>
    </header>
  )
}
