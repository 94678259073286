import type { UserProfile } from "@dynamic-labs/sdk-react-core"
import { useAnalytics } from "@web/hooks/useAnalytics"
import { useToast } from "@web/hooks/useToast"
import { trpcClient as trpc } from "@web/lib/trpc"
import { useAppStore } from "@web/stores"

const events = {
  login: "dynamic:fan_login",
  create: "dynamic:fan_create",
  update: "dynamic:profile_update",
}

// NOTE: Should NOT update the fan store to prevent race conditions with Dynamic callbacks
export function useFanMutations() {
  const analytics = useAnalytics(events)
  const { showError, showWarning } = useToast()

  async function fetchExistingFan() {
    try {
      const existingFan = await trpc.fan.get.query()
      analytics.login({ ...existingFan })

      return existingFan
    } catch (error) {
      showError("Failed to fetch existing fan", error)
    }
  }

  async function createNewFan(userProfile: UserProfile) {
    const { email, newUser, username, userId, wallet } = userProfile
    const referralId = useAppStore.getState().referralId

    try {
      if (!email) throw new Error("Email is required")
      analytics.create({
        email,
        newUser,
        referralId,
        userId,
        username,
        wallet,
      })

      const newFan = await trpc.fan.create.mutate({ email, referralId, username })
      return newFan
    } catch (error) {
      showError("Failed to save new fan", error)
    }
  }

  async function updateExistingFan(userProfile?: UserProfile, referralId?: string) {
    const email = userProfile?.email

    try {
      analytics.update({ email, referralId })

      const [existingFan] = await trpc.fan.update.mutate({ email, referralId })
      return existingFan
    } catch (error) {
      showWarning("Failed to update existing fan", error)
    }
  }

  return { createNewFan, fetchExistingFan, updateExistingFan }
}
