import type { OwnerResponse } from "@api/types"
import { type QueryClient, skipToken } from "@tanstack/react-query"
import { getQueryKey } from "@trpc/react-query"
import { trpc, trpcLoaderClient } from "@web/lib/trpc"

export async function prefetchOwners(queryClient: QueryClient, contract: string, slug: string) {
  const queryKey = getQueryKey(trpc.collective.getOwners, { contract, slug }, "query")
  return await queryClient.ensureQueryData({
    queryKey,
    queryFn: () => trpcLoaderClient.collective.getOwners.query({ contract, slug }),
  })
}

export function useOwners(contract: string, slug: string, initialData?: OwnerResponse[]) {
  return trpc.collective.getOwners.useQuery(!!contract || !!slug ? { contract, slug } : skipToken, {
    refetchInterval: 5_000, // Default polling interval
    initialData,
  })
}

export function useOwnersSuspense(contract: string, slug: string, initialData?: OwnerResponse[]) {
  return trpc.collective.getOwners.useSuspenseQuery(
    { contract, slug },
    {
      refetchInterval: 5_000, // Default polling interval
      initialData,
    },
  )
}
