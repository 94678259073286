import type { Fan } from "@api/types"
import { ClaimButton } from "@web/components/buttons/ClaimButton"
import { PackButton } from "@web/components/buttons/PackButton"
import { RedeemButton } from "@web/components/buttons/RedeemButton"
import { ShareButton } from "@web/components/buttons/ShareButton"
import { useFanMutations } from "@web/hooks/mutations/useFanMutations"
import { useClaims } from "@web/hooks/queries/useClaims"
import { useCollectives } from "@web/hooks/queries/useCollectives"
import { useContracts } from "@web/hooks/queries/useContracts"
import { useHoldings } from "@web/hooks/queries/useHoldings"
import { usePack } from "@web/hooks/queries/usePack"
import { usePrices } from "@web/hooks/queries/usePrices"
import { useLoginContext } from "@web/hooks/useLoginContext"
import { usePWAMode } from "@web/hooks/usePWAMode"
import { usePricedCollectives } from "@web/hooks/usePricedCollectives"
import { cn } from "@web/lib/utils"
import { useAppStore } from "@web/stores"
import { useFanStore } from "@web/stores/fan"
import { useEffect } from "react"
import { useParams } from "react-router-dom"

export function ButtonController() {
  const isPWA = usePWAMode()
  const { contract, slug } = useParams()
  const { fan, setFan } = useFanStore((s) => ({ fan: s.fan, setFan: s.setFan }))
  const activeContract = useAppStore((s) => s.lastContract)
  const { isLoggedIn } = useLoginContext()
  const { fetchExistingFan } = useFanMutations()

  // Claim button
  const { data: claims = [], refetch } = useClaims()

  // Redeem button
  const {
    data: [collective] = [],
  } = useCollectives(contract, slug)
  const {
    data: [holding] = [],
  } = useHoldings({
    contract: collective?.contract,
    slug: collective?.slug,
    address: fan?.address,
  })
  const {
    data: [contractData] = [],
  } = useContracts(collective?.contract)
  const {
    data: [price] = [],
  } = usePrices(collective?.contract, collective?.slug)
  const [pricedCollective] = usePricedCollectives([collective], [price])

  // Share button
  const {
    data: [sharePack] = [],
  } = usePack(fan?.shareCode ?? undefined)

  // Pack button
  const {
    data: [packContract] = [],
  } = useContracts(activeContract)

  // Conditions
  const isHomePage = !slug
  const hasClaims = isHomePage && claims.length > 0
  const isSelling = isHomePage && !hasClaims && packContract?.packCodes.length > 0
  const canRedeem = Boolean(
    isLoggedIn &&
      !isHomePage &&
      holding?.fanVotes > 0 &&
      (collective?.position ?? 0) > 0 &&
      ["postgame", "finished"].includes(contractData?.state),
  )

  const hasSharesInActive = !!holding && contractData?.state === "active"
  const isVisible = (isSelling && !hasSharesInActive) || hasClaims || canRedeem

  // Refetch existing fan information
  useEffect(() => {
    async function fetchShareCode() {
      const { shareCode } = (await fetchExistingFan()) as Fan
      if (fan?.id && shareCode) setFan({ ...fan, shareCode })
    }
    if (!isLoggedIn) return
    fetchShareCode()
  }, [isLoggedIn])

  // Fetch claims when the user is logged in
  useEffect(() => {
    if (!isLoggedIn) return
    refetch()
  }, [isLoggedIn, refetch])

  return (
    // Enter Bracket Bar
    <div
      className={cn(
        "fixed bottom-0 z-20 hidden h-fit w-full max-w-md flex-col items-center bg-gradient-to-t from-white via-80% via-white to-transparent px-4 py-[1.11rem]",
        { flex: isVisible },
        { "mb-20": isPWA && !slug },
      )}
    >
      <div className="flex w-full items-center space-x-[.45rem]">
        {!slug && <ShareButton pack={sharePack} />}
        {isSelling && <PackButton />}
        {hasClaims && <ClaimButton claims={claims} />}
        {canRedeem && <RedeemButton collective={pricedCollective} />}
      </div>
    </div>
  )
}
